import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import { Container } from "./common/Container";
import { Button } from "./common/Button";
import { Avatar } from "./common/Avatar";

import UserOne from "../assets/user1.png";
import UserTwo from "../assets/user2.png";
import UserThree from "../assets/user3.png";
import UserFour from "../assets/user4.png";
import UserFive from "../assets/user5.png";
import UserSix from "../assets/user6.png";

const reviews = [
  {
    id: 1,
    text: "Phosfluorescently engage worldwide methodologies with web-enabled technology. Interactively coordinate proactive commerce via process-centric outside thinking to pursue scalable technology.",
    name: "David Miller",
    position: "HR Manager",
    imgSrc: UserOne,
  },
  {
    id: 2,
    text: "Authoritatively empower best-of-breed networks with cross-unit e-markets. Quickly revolutionize impactful supply chains with resource maximizing collaboration.",
    name: "Sarah Connor",
    position: "Marketing Director",
    imgSrc: UserTwo,
  },
  {
    id: 3,
    text: "Globally streamline enterprise-wide relationships via adaptive markets. Completely embrace effective communities for global process improvements.",
    name: "John Doe",
    position: "Software Engineer",
    imgSrc: UserThree,
  },
  {
    id: 4,
    text: "Seamlessly synthesize cross-platform partnerships via impactful results. Efficiently develop end-to-end materials rather than user-centric experiences.",
    name: "Jane Smith",
    position: "Project Manager",
    imgSrc: UserFour,
  },
  {
    id: 5,
    text: "Monotonectally reconceptualize one-to-one solutions through cutting-edge paradigms. Uniquely enhance fully tested infrastructures rather than ubiquitous initiatives.",
    name: "Robert Johnson",
    position: "Product Designer",
    imgSrc: UserFive,
  },
  {
    id: 6,
    text: "Assertively leverage other's future-proof channels rather than next-generation methodologies. Quickly revolutionize fully researched infrastructures.",
    name: "Emily Davis",
    position: "Customer Support",
    imgSrc: UserSix,
  },
  {
    id: 7,
    text: "Competently reconceptualize fully tested core competencies through excellent experiences. Monotonectally brand cross-unit niche markets.",
    name: "Michael Brown",
    position: "Data Analyst",
    imgSrc: UserOne,
  },
];

const Testimonial = () => {
  return (
    <>
      <div className="from-custom-dark-blue relative overflow-hidden bg-gradient-to-t to-[#021823] md:pt-32 pt-24 lg:pb-32 md:pb-24 pb-20">
        <div className="absolute top-0 z-10 w-full">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#ecf0f3"
              fill-opacity="1"
              d="M0,0L1440,96L1440,0L0,0Z"
            ></path>
          </svg>
        </div>
        {/* Floating Avatars */}
        <div className="floating md:block hidden absolute xl:left-40 left-4 lg:top-60 top-40 z-30">
          <Avatar imgSrc={UserOne} width={120} height={120} />
        </div>
        <div className="floating md:block hidden absolute right-10 top-20 z-30">
          <Avatar imgSrc={UserTwo} width={120} height={120} />
        </div>
        <div className="floating md:block hidden absolute right-52 lg:top-40 top-56 z-30">
          <Avatar imgSrc={UserThree} width={60} height={60} />
        </div>
        <div className="floating md:block hidden absolute xl:left-10 left-32 top-10 z-30">
          <Avatar imgSrc={UserFour} width={90} height={90} />
        </div>
        <div className="floating md:block hidden absolute bottom-10 left-20 z-30">
          <Avatar imgSrc={UserFive} width={60} height={60} />
        </div>
        <div className="floating md:block hidden absolute xl:bottom-20 bottom-0 right-20 z-30">
          <Avatar imgSrc={UserSix} width={90} height={90} />
        </div>

        <Container className="relative z-20 text-center">
          <div>
            <h2 className="mx-auto text-[24px] font-bold leading-snug text-white sm:text-[36px] lg:text-[40px] xl:text-[44px] 2xl:text-[48px]">
              <span className="drop-shadow-md">Our Happy </span>
              <span className="text-custom-orange relative whitespace-nowrap leading-normal">
                <svg
                  aria-hidden="true"
                  viewBox="0 0 418 42"
                  className="absolute left-0 top-2/3 h-[0.58em] w-full fill-gray-400/70"
                  preserveAspectRatio="none"
                >
                  <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
                </svg>
                <span className="relative">Client's</span>
              </span>
            </h2>
          </div>
          <div className="w-full">
            <Swiper
              pagination={{
                dynamicBullets: true,
              }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              loop={true}
              modules={[Pagination, Autoplay]}
              className="mySwiper"
            >
              {reviews.map((review) => (
                <SwiperSlide key={review.id}>
                  <div className="mx-auto flex max-w-[600px] flex-col items-center">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#faa21b"
                        viewBox="0 -5 34 34"
                        className="opacity-30 md:h-[80px] md:w-[80px] h-[60px] w-[60px]"
                      >
                        <path d="m31.2 0h-7.2l-4.8 9.6v14.4h14.4v-14.4h-7.2zm-19.2 0h-7.2l-4.8 9.6v14.4h14.4v-14.4h-7.2z"></path>
                      </svg>
                    </span>
                    <p className="fontSuse my-4 md:text-lg sm:text-base text-sm tracking-wide text-white">
                      {review.text}
                    </p>
                    <div className="mx-auto flex w-fit items-center">
                      <Avatar
                        imgSrc={review.imgSrc}
                        width={60}
                        height={60}
                        className="mr-6 border-2 border-gray-200"
                      />
                      <div className="flex-1 text-start">
                        <h6 className="fontSuse text-xl font-medium text-white">
                          {review.name}
                        </h6>
                        <p className="text-sm text-gray-500">
                          {review.position}
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Testimonial;

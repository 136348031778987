import React, { useEffect } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import HomePage from "./pages/home/home";
import ServiceDetails from "./pages/services/service-details";
import CourseDetails from "./pages/courses/course-details";
import AboutDetails from "./pages/about/about";
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer";
import Courses from "./pages/courses/courses";

const App = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <div className="fixed left-0 top-0 z-50 w-full xl:top-8">
        <Navbar />
      </div>
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/service-details/:id" element={<ServiceDetails />} />
          <Route path="/about" element={<AboutDetails />} />
          <Route path="/courses" element={<Courses />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
};

export default App;

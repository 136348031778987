import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import WebImg from "../assets/web.png";
import { Button } from "./common/Button";
import { Container } from "./common/Container";
import SwirlyDoodle from "./common/SwirlyDoodle";
import { FaCopy } from "react-icons/fa";

const Card = ({ onMouseEnter, onMouseLeave, title, description, icon, id }) => {
  return (
    <Link to={`/service-details/${id}`}>
      <div
        className="items- relative z-20 flex h-full cursor-pointer flex-col items-start justify-between overflow-hidden rounded-2xl border border-[#d4d4d491] bg-gradient-box px-6 py-6 shadow-custom-box"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="mb-12 flex w-full items-center justify-between xl:mb-20">
          <span className="flex h-[70px] w-[70px] items-center justify-center rounded-md">
            <img
              src={icon}
              alt="img not found"
              width={"100%"}
              className="filter-orange"
            />
          </span>
        </div>
        <div>
          <h4 className="mb-2 text-lg font-semibold tracking-tight text-custom-dark-blue md:text-xl">
            {title}
          </h4>
          <p className="mb-4 text-xs text-gray-600 md:text-sm">{description}</p>
          <Button
            className={
              "border border-custom-dark-blue bg-transparent text-xs uppercase text-custom-dark-blue"
            }
          >
            Discover More
          </Button>
        </div>
      </div>
    </Link>
  );
};

const Services = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const containerRef = useRef(null);
  const backgroundRef = useRef(null);

  const handleMouseEnter = (index, event) => {
    setHoveredCard(index);

    const card = event.currentTarget;
    const rect = card.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();

    // Set the position of the background box relative to the container
    backgroundRef.current.style.transform = `translate3d(${rect.left - containerRect.left}px, ${rect.top - containerRect.top}px, 0)`;
    backgroundRef.current.style.width = `${rect.width}px`;
    backgroundRef.current.style.height = `${rect.height}px`;
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const cards = [
    {
      id: "graphic-design",
      title: "Graphic Design",
      description:
        "We provide professional graphic design services, crafting visually stunning and impactful designs.",
      icon: WebImg,
    },
    {
      id: "bookkeeping",
      title: "Bookkeeping",
      description:
        "Accurate bookkeeping solutions to help you manage finances efficiently and stay organized.",
      icon: WebImg,
    },
    {
      id: "video-editing",
      title: "Video Editing",
      description:
        "Professional video editing services to enhance your content with high-quality visuals.",
      icon: WebImg,
    },
    {
      id: "web-development",
      title: "Web Development",
      description:
        "Custom web development solutions to create dynamic, responsive, and user-friendly websites.",
      icon: WebImg,
    },
    {
      id: "ui-ux-design",
      title: "UI/UX Design",
      description:
        "Intuitive UI/UX design services to enhance user experiences and drive engagement.",
      icon: WebImg,
    },
    {
      id: "digital-marketing",
      title: "Digital Marketing",
      description:
        "Result-driven digital marketing strategies to grow your online presence and business.",
      icon: WebImg,
    },
  ];

  useEffect(() => {
    // Reset the background box position and size on mount
    if (backgroundRef.current) {
      backgroundRef.current.style.transform = "translate3d(0, 0, 0)";
      backgroundRef.current.style.width = "0";
      backgroundRef.current.style.height = "0";
    }
  }, []);

  return (
    <Container>
      <div className="head mx-auto mb-14 max-w-[600px] text-center">
        <span className="fontSuse mb-2 inline-block text-base font-medium text-custom-orange md:mb-3">
          Our Latest Services
        </span>
        <h2 className="text-[24px] font-bold leading-snug text-custom-dark-blue sm:text-[36px] lg:text-[40px] xl:text-[44px] 2xl:text-[48px]">
          What Kind of Services We are Offering
        </h2>
      </div>

      <div className="relative" ref={containerRef}>
        <div
          ref={backgroundRef}
          className="absolute left-0 top-0 z-10 rounded-2xl bg-white transition-all duration-300 ease-in-out"
        ></div>

        <div className="relative z-10 flex flex-wrap">
          {cards.map((card, index) => (
            <div
              className="w-full py-3 sm:w-[50%] sm:p-3 lg:w-[33.33%] xl:p-4"
              key={index}
              onMouseEnter={(event) => handleMouseEnter(index, event)}
              onMouseLeave={handleMouseLeave}
            >
              <Card
                id={card.id}
                title={card.title}
                description={card.description}
                icon={card.icon}
              />
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Services;

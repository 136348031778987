import React, { useEffect, useRef, useState } from "react";
import { FaArrowDown, FaArrowRight, FaCopy, FaPlay } from "react-icons/fa6";

import ImgOne from "../assets/demo3.jfif";
import { Container } from "./common/Container";

const faqs = [
  {
    question: "What courses do you offer?",
    answer:
      "We provide a wide range of courses in web development, data science, cloud computing, cybersecurity, and more. Our programs are designed to equip you with the latest industry skills.",
  },
  {
    question: "Are the courses online or in-person?",
    answer:
      "We offer both online and in-person learning options. Our flexible learning approach allows you to choose the format that best suits your schedule and learning preferences.",
  },
  {
    question: "Do you provide job placement assistance?",
    answer:
      "Yes! We assist students with job placements through career guidance, resume-building workshops, and connections with hiring companies in the tech industry.",
  },
];

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(0);
  const contentRefs = useRef([]);

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    if (contentRefs.current) {
      contentRefs.current.forEach((ref, idx) => {
        if (ref) {
          ref.style.height =
            openIndex === idx ? `${ref.scrollHeight}px` : "0px";
        }
      });
    }
  }, [openIndex]);

  return (
    <Container>
      <div className="flex flex-col justify-between lg:flex-row lg:space-x-10 xl:space-x-16">
        <div className="mb-12 flex w-full flex-1 flex-col justify-center lg:mb-0">
          <span className="fontSuse mb-2 flex items-center justify-center text-base font-medium text-custom-orange md:mb-3 md:justify-start">
            <FaCopy className="mr-2" /> Everything You Need to Know About Us
          </span>
          <h2 className="mb-10 text-[24px] font-bold leading-snug text-custom-dark-blue sm:text-[36px] lg:text-[40px] xl:text-[44px] 2xl:text-[48px]">
          Frequently Asked Questions
          </h2>
          {faqs.map((faq, index) => (
            <div key={index} className="mb-5 rounded-xl bg-white p-4">
              <button
                onClick={() => handleToggle(index)}
                className={`flex w-full items-center justify-between text-lg font-medium ${openIndex === index ? "text-custom-orange" : "text-custom-dark-blue"}`}
              >
                {faq.question}
                <span className="ml-4">
                  {openIndex === index ? <FaArrowDown /> : <FaArrowRight />}
                </span>
              </button>
              <div
                ref={(el) => (contentRefs.current[index] = el)}
                className="overflow-hidden transition-all duration-500 ease-in-out"
              >
                <div className="mt-3 border-t border-gray-300 pt-4">
                  <p className="text-left text-sm text-gray-700 md:text-base">
                    {faq.answer}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="lg:w-[420px]">
          <img
            src={ImgOne}
            alt="FAQS"
            className="h-full w-full rounded-3xl object-cover"
          />
        </div>
      </div>
    </Container>
  );
};

export default FAQSection;

import React from "react";
import { motion } from "framer-motion";

import Logo from "../assets/logo.svg";
import Calsa from "../assets/calsa.png";

const logos = [Logo, Calsa, Logo, Calsa, Logo, Calsa];

const Parnerts = () => {
  return (
    <div className="relative w-full overflow-hidden">
      <h2 className="mb-10 text-center text-[24px] font-bold leading-snug text-custom-dark-blue sm:text-[36px]">
        Our Trusted Clients
      </h2>
      <div className="flex w-full space-x-20">
        <motion.div
          className="flex space-x-20"
          initial={{ x: 0 }}
          animate={{ x: "-100%" }}
          transition={{
            repeat: Infinity,
            repeatType: "loop",
            ease: "linear",
            duration: 120,
          }}
        >
          {[
            ...logos,
            ...logos,
            ...logos,
            ...logos,
            ...logos,
            ...logos,
            ...logos,
            ...logos,
            ...logos,
            ...logos,
            ...logos,
            ...logos,
            ...logos,
            ...logos,
            ...logos,
            ...logos,
            ...logos,
            ...logos,
            ...logos,
          ].map((logo, index) => (
            <div key={index} className="h-16 w-40 flex-shrink-0">
              <img
                src={logo}
                alt={`Partner ${index}`}
                className="h-full w-full object-contain"
              />
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Parnerts;

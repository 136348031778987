import React from "react";
import { Container } from "./common/Container";
import SwirlyDoodle from "./common/SwirlyDoodle";
import {
  ClockIcon,
  EnvelopeIcon,
  MapPinIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";

import ContactImg from "../assets/contact.png";

const Contact = () => {
  return (
    <Container>
      <div className="head mb-10 md:mb-20">
        <h2 className="mb-4 text-[24px] font-bold leading-snug text-custom-dark-blue sm:text-[36px] lg:text-[40px] xl:text-[44px] 2xl:text-[48px]">
          Feel free to{" "}
          <span className="relative text-custom-orange">get in touch!</span>
        </h2>
        <p className="max-w-[800px] text-sm text-gray-600 md:text-base">
          Let’s talk about a project, collaboration or an idea you may have
        </p>
        <div className="mt-12 flex flex-wrap justify-between">
          <div className="flex w-full items-center sm:w-[48%] lg:w-[30%]">
            <span className="mr-4 flex h-[50px] w-[50px] items-center justify-center rounded-lg border border-[#d4d4d491] bg-gradient-box shadow-custom-box md:h-[90px] md:w-[90px]">
              <MapPinIcon className="w-6 text-custom-orange md:w-10" />
            </span>
            <div className="flex-1">
              <h4 className="fontSuse mb-2 text-sm font-semibold uppercase text-custom-dark-blue">
                Office Address
              </h4>
              <a href="#" className="block text-sm text-gray-600">
                Lower chitral, Pakistan
              </a>
            </div>
          </div>

          <div className="mb-4 flex w-full items-center sm:mb-0 sm:w-[48%] lg:w-[30%]">
            <span className="mr-4 flex h-[50px] w-[50px] items-center justify-center rounded-lg border border-[#d4d4d491] bg-gradient-box shadow-custom-box md:h-[90px] md:w-[90px]">
              <PhoneIcon className="w-6 text-custom-orange md:w-10" />
            </span>
            <div className="flex-1">
              <h4 className="fontSuse mb-2 text-sm font-semibold uppercase text-custom-dark-blue">
                Contact Us
              </h4>
              <a
                href="mailto:hubbeconnect7@gmail.com"
                className="mb-1 block text-sm text-gray-600"
              >
                hubbeconnect7@gmail.com
              </a>
              <a
                href="tel:+923400180008"
                className="block text-sm text-gray-600"
              >
                (+92) 3400180008
              </a>
            </div>
          </div>

          <div className="mb-4 flex w-full items-center sm:mb-6 lg:mb-0 lg:w-[40%]">
            <span className="mr-4 flex h-[50px] w-[50px] items-center justify-center rounded-lg border border-[#d4d4d491] bg-gradient-box shadow-custom-box md:h-[90px] md:w-[90px]">
              <ClockIcon className="w-6 text-custom-orange md:w-10" />
            </span>
            <div className="flex-1">
              <h4 className="fontSuse mb-2 text-sm font-semibold uppercase text-custom-dark-blue">
                Working Hours
              </h4>
              <div className="text-sm text-gray-600">
                <span className="mb-1 block">Weekdays 8:00 AM - 10:00 PM</span>
                <span className="block">Closed on Weekends</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center rounded-2xl border border-[#d4d4d491] bg-gradient-box p-4 shadow-custom-box sm:p-6 md:p-8">
        <form className="w-full md:mr-4 md:w-[60%] lg:w-[500px] 2xl:mr-20">
          <div className="flex flex-col gap-x-4 sm:flex-row sm:gap-x-6">
            <input
              type="text"
              id="name"
              placeholder="Name"
              className="fontSuse mb-4 w-full rounded-md bg-white px-4 py-3 text-sm text-custom-black outline-none ring-1 ring-inset ring-gray-300 transition duration-300 placeholder:text-xs placeholder:font-medium placeholder:uppercase placeholder:tracking-wider placeholder:text-gray-500 focus:ring-2 focus:ring-custom-orange sm:mb-6"
            />
            <input
              type="text"
              id="phone"
              placeholder="Phone"
              className="fontSuse mb-4 w-full rounded-md bg-white px-4 py-3 text-sm text-custom-black outline-none ring-1 ring-inset ring-gray-300 transition duration-300 placeholder:text-xs placeholder:font-medium placeholder:uppercase placeholder:tracking-wider placeholder:text-gray-500 focus:ring-2 focus:ring-custom-orange sm:mb-6"
            />
          </div>
          <input
            type="email"
            id="email"
            placeholder="Email"
            className="fontSuse mb-4 w-full rounded-md bg-white px-4 py-3 text-sm text-custom-black outline-none ring-1 ring-inset ring-gray-300 transition duration-300 placeholder:text-xs placeholder:font-medium placeholder:uppercase placeholder:tracking-wider placeholder:text-gray-500 focus:ring-2 focus:ring-custom-orange sm:mb-6"
          />
          <input
            type="text"
            id="subject"
            placeholder="Subject"
            className="fontSuse mb-4 w-full rounded-md bg-white px-4 py-3 text-sm text-custom-black outline-none ring-1 ring-inset ring-gray-300 transition duration-300 placeholder:text-xs placeholder:font-medium placeholder:uppercase placeholder:tracking-wider placeholder:text-gray-500 focus:ring-2 focus:ring-custom-orange sm:mb-6"
          />
          <textarea
            id="message"
            placeholder="Message"
            className="resize-x-none fontSuse mb-4 min-h-[100px] w-full rounded-md bg-white px-4 py-3 text-sm text-custom-black outline-none ring-1 ring-inset ring-gray-300 transition duration-300 placeholder:text-xs placeholder:font-medium placeholder:uppercase placeholder:tracking-wider placeholder:text-gray-500 focus:ring-2 focus:ring-custom-orange sm:mb-6"
          ></textarea>
        </form>
        <div className="hidden flex-1 md:block">
          <div className="mx-auto w-full xl:w-[70%]">
            <img src={ContactImg} alt="contact us" width={"100%"} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Contact;

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import WebImg from "../../assets/web.png";
import { Button } from "../../components/common/Button";

const Card = ({ onMouseEnter, onMouseLeave, title, description, icon, id }) => {
  return (
      <div
        className="items- relative z-20 flex h-full cursor-pointer flex-col items-start justify-between overflow-hidden rounded-2xl border border-[#d4d4d491] bg-gradient-box px-6 py-6 shadow-custom-box"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="mb-12 flex w-full items-center justify-between xl:mb-20">
          <span className="flex h-[70px] w-[70px] items-center justify-center rounded-md">
            <img
              src={icon}
              alt="img not found"
              width={"100%"}
              className="filter-orange"
            />
          </span>
        </div>
        <div>
          <h4 className="mb-2 text-lg font-semibold tracking-tight text-custom-dark-blue md:text-xl">
            {title}
          </h4>
          <p className="mb-4 text-xs text-gray-600 md:text-sm">{description}</p>
          <Button
            className="border border-custom-dark-blue bg-transparent text-xs uppercase text-custom-dark-blue"
            asChild
          >
            <a
              href="https://forms.gle/9KF8po65KQX84Hy76"
              target="_blank"
              rel="noreferrer"
            >
              Apply Now
            </a>
          </Button>
        </div>
      </div>
  );
};

const ListCourses = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const containerRef = useRef(null);
  const backgroundRef = useRef(null);

  const handleMouseEnter = (index, event) => {
    setHoveredCard(index);

    const card = event.currentTarget;
    const rect = card.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();

    // Set the position of the background box relative to the container
    backgroundRef.current.style.transform = `translate3d(${rect.left - containerRect.left}px, ${rect.top - containerRect.top}px, 0)`;
    backgroundRef.current.style.width = `${rect.width}px`;
    backgroundRef.current.style.height = `${rect.height}px`;
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const cards = [
    {
      id: "graphic-design",
      title: "Graphic Design",
      description:
        "Unlock your creativity and become a skilled graphic designer with our comprehensive course!",
      icon: WebImg,
    },
    {
      id: "bookkeeping",
      title: "Bookkeeping",
      description:
        "Master the fundamentals of bookkeeping and take control of your finances!",
      icon: WebImg,
    },
    {
      id: "video-editing",
      title: "Video Editing",
      description:
        "Master the art of video editing and create stunning visual stories!",
      icon: WebImg,
    },
    {
      id: "web-development",
      title: "Web Development",
      description:
        "Build a career in web development and create dynamic websites!",
      icon: WebImg,
    },
    {
      id: "ui-ux-design",
      title: "UI/UX Design",
      description:
        "Master the art of user experience and user interface design!",
      icon: WebImg,
    },
    {
      id: "digital-marketing",
      title: "Digital Marketing",
      description:
        "Master the art of digital marketing and transform your business!",
      icon: WebImg,
    },
    {
      id: "freelancing",
      title: "Freelancing",
      description:
        "Launch a successful freelancing career and achieve financial freedom!",
      icon: WebImg,
    },
  ];

  useEffect(() => {
    // Reset the background box position and size on mount
    if (backgroundRef.current) {
      backgroundRef.current.style.transform = "translate3d(0, 0, 0)";
      backgroundRef.current.style.width = "0";
      backgroundRef.current.style.height = "0";
    }
  }, []);

  return (
    <div className="relative" ref={containerRef}>
      <div
        ref={backgroundRef}
        className="absolute left-0 top-0 z-10 rounded-2xl bg-white transition-all duration-300 ease-in-out"
      ></div>

      <div className="relative z-10 flex flex-wrap">
        {cards.map((card, index) => (
          <div
            className="w-full py-3 sm:w-[50%] sm:p-3 lg:w-[33.33%] xl:p-4"
            key={index}
            onMouseEnter={(event) => handleMouseEnter(index, event)}
            onMouseLeave={handleMouseLeave}
          >
            <Card
              id={card.id}
              title={card.title}
              description={card.description}
              icon={card.icon}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListCourses;

import { Link, useParams } from "react-router-dom";
import DemoImg from "../../assets/demo.avif";
import DemoImgTwo from "../../assets/demo2.jfif";
import DemoImgThree from "../../assets/demo3.jfif";
import { Container } from "../../components/common/Container";
import { HomeIcon } from "@heroicons/react/20/solid";
import SwirlyDoodle from "../../components/common/SwirlyDoodle";
import Contact from "../../components/contact";
import { useEffect } from "react";
import { Button } from "../../components/common/Button";
import { FaArrowDown, FaArrowRight, FaCopy, FaPlay } from "react-icons/fa6";

const pages = [
  { name: "Service", href: "#", current: false },
  { name: "Services Details", href: "#", current: true },
];

const serviceDetails = [
  {
    id: "graphic-design",
    title: "Graphic Design Services",
    description:
      "Creative and Professional Graphic Design Solutions to Elevate Your Brand Identity",
    content: [
      {
        text: "Strong visuals are key to a powerful brand. At Hub Be Connect, we craft stunning, high-quality designs that enhance brand identity, engage audiences, and communicate messages effectively. Our team ensures every design aligns with your brand’s vision and goals.",
      },
      {
        sectionTitle: "Why Choose Our Graphic Design Services?",
        text: "Our designs go beyond looks—they serve a purpose. Whether it's brand awareness, customer engagement, or improved user experience, we create visuals that leave a lasting impression.",
      },
      {
        features: [
          {
            title: "Logo & Branding",
            text: "Unique, memorable logos and branding solutions.",
          },
          {
            title: "Marketing Collateral",
            text: "Brochures, flyers, and business cards for digital and print.",
          },
          {
            title: "Social Media Graphics",
            text: "Engaging visuals to boost your online presence.",
          },
          {
            title: "UI/UX Design",
            text: "User-friendly website and app interface designs.",
          },
          {
            title: "Infographics",
            text: "Simplified and visually appealing data representation.",
          },
          {
            title: "Packaging Design",
            text: "Attractive and functional product packaging.",
          },
          {
            title: "Custom Illustrations",
            text: "Unique graphics and icons for marketing materials.",
          },
        ],
      },
    ],
  },
  {
    id: "bookkeeping",
    title: "Bookkeeping Services",
    description:
      "Reliable and Accurate Bookkeeping Solutions to Streamline Your Financial Management",
    content: [
      {
        text: "Accurate financial records are essential for business success. At Hub Be Connect, we provide comprehensive bookkeeping services that ensure your financial data is organized, up-to-date, and compliant with regulations. Our team is dedicated to maintaining the integrity of your financial records while providing valuable insights for decision-making.",
      },
      {
        sectionTitle: "Why Choose Our Bookkeeping Services?",
        text: "Our bookkeeping services go beyond just managing numbers. We aim to streamline your financial processes, improve cash flow, and ensure your business stays on track. Whether you're a small business or a growing enterprise, we tailor our services to meet your specific needs.",
      },
      {
        features: [
          {
            title: "Financial Statement Preparation",
            text: "We prepare accurate balance sheets, income statements, and cash flow statements.",
          },
          {
            title: "Expense Tracking",
            text: "Track and categorize all your business expenses to maintain financial transparency.",
          },
          {
            title: "Tax Compliance & Filing",
            text: "Ensure tax filings are accurate and timely, minimizing tax liabilities.",
          },
          {
            title: "Payroll Processing",
            text: "Manage employee payroll and ensure timely payments and tax deductions.",
          },
          {
            title: "Bank Reconciliation",
            text: "Reconcile your bank statements with your financial records to ensure accuracy.",
          },
          {
            title: "Cash Flow Management",
            text: "Monitor and manage cash flow to ensure liquidity and financial stability.",
          },
          {
            title: "Custom Financial Reports",
            text: "Generate customized reports to gain insights into your business’s financial health.",
          },
        ],
      },
    ],
  },
  {
    id: "video-editing",
    title: "Video Editing Services",
    description:
      "Professional Video Editing Solutions to Bring Your Vision to Life",
    content: [
      {
        text: "Video is one of the most powerful ways to communicate your message. At Hub Be Connect, we offer expert video editing services that transform raw footage into polished, high-quality content. Whether for social media, marketing campaigns, or internal use, we ensure your videos are engaging and impactful.",
      },
      {
        sectionTitle: "Why Choose Our Video Editing Services?",
        text: "Our video editing services are designed to deliver visually stunning content that tells your story in the most compelling way. We work with you to create videos that not only capture attention but also resonate with your target audience.",
      },
      {
        features: [
          {
            title: "Custom Video Editing",
            text: "Tailored editing services to suit your unique vision and brand style.",
          },
          {
            title: "Social Media Videos",
            text: "Engaging and shareable videos optimized for social media platforms.",
          },
          {
            title: "Commercial & Promotional Videos",
            text: "High-impact promotional videos to market your products or services.",
          },
          {
            title: "Corporate Videos",
            text: "Professional videos for company presentations, training, and internal communications.",
          },
          {
            title: "Music Videos",
            text: "Creative editing to bring your music video concepts to life.",
          },
          {
            title: "Event & Documentary Videos",
            text: "Document your events with seamless editing to preserve memories and stories.",
          },
          {
            title: "Motion Graphics & Animations",
            text: "Add dynamic visual elements and animations to enhance your video content.",
          },
        ],
      },
    ],
  },
  {
    id: "web-development",
    title: "Web Development Services",
    description:
      "Innovative and Scalable Web Development Solutions to Enhance Your Online Presence",
    content: [
      {
        text: "Your website is the cornerstone of your online presence. At Hub Be Connect, we specialize in creating fast, secure, and user-friendly websites that are tailored to meet your business objectives. Our team of skilled developers ensures that your site is both functional and visually appealing, offering an exceptional user experience on any device.",
      },
      {
        sectionTitle: "Why Choose Our Web Development Services?",
        text: "We understand that a well-designed website can make or break your business. Our web development services are aimed at creating websites that drive conversions, enhance user experience, and reflect your brand identity. Whether you need a simple informational site or a complex web application, we have the expertise to bring your vision to life.",
      },
      {
        features: [
          {
            title: "Custom Website Design",
            text: "Unique, responsive web design that aligns with your brand and goals.",
          },
          {
            title: "E-Commerce Development",
            text: "Build scalable and secure online stores with payment integrations and inventory management.",
          },
          {
            title: "Content Management Systems (CMS)",
            text: "User-friendly CMS development for easy content updates and management.",
          },
          {
            title: "Web Application Development",
            text: "Custom web apps designed to solve specific business problems and improve efficiency.",
          },
          {
            title: "SEO Optimization",
            text: "Ensure your website is optimized for search engines to boost visibility and traffic.",
          },
          {
            title: "Mobile Optimization",
            text: "Responsive design ensuring optimal performance across all devices, including smartphones and tablets.",
          },
          {
            title: "API Integration",
            text: "Seamlessly integrate third-party services and applications to extend your website’s functionality.",
          },
        ],
      },
    ],
  },
  {
    id: "ui-ux-design",
    title: "UI/UX Design Services",
    description:
      "Intuitive and Engaging UI/UX Design Solutions to Enhance User Satisfaction",
    content: [
      {
        text: "A great user experience starts with exceptional design. At Hub Be Connect, we focus on creating user-centered designs that provide seamless, enjoyable interactions with your digital products. Our UI/UX design services ensure that your website or app is not only visually appealing but also easy to navigate, increasing user satisfaction and engagement.",
      },
      {
        sectionTitle: "Why Choose Our UI/UX Design Services?",
        text: "We understand the importance of both aesthetics and functionality in delivering the best user experience. Our team combines creativity with user behavior analysis to craft interfaces that are both intuitive and engaging, helping your users achieve their goals effortlessly.",
      },
      {
        features: [
          {
            title: "User Research & Analysis",
            text: "Conduct in-depth research to understand user needs, behaviors, and pain points.",
          },
          {
            title: "Wireframing & Prototyping",
            text: "Develop wireframes and interactive prototypes to visualize the user journey.",
          },
          {
            title: "UI Design",
            text: "Create visually appealing and cohesive design systems that reflect your brand.",
          },
          {
            title: "UX Strategy & Optimization",
            text: "Implement strategies to improve user flow, reduce friction, and enhance usability.",
          },
          {
            title: "Mobile App Design",
            text: "Design user-friendly mobile interfaces that ensure smooth interaction on mobile devices.",
          },
          {
            title: "Usability Testing",
            text: "Test designs with real users to identify areas for improvement and optimize usability.",
          },
          {
            title: "Responsive Web Design",
            text: "Design websites that are fully responsive and provide a seamless experience across devices.",
          },
        ],
      },
    ],
  },
  {
    id: "digital-marketing",
    title: "Digital Marketing Services",
    description:
      "Comprehensive Digital Marketing Solutions to Grow Your Brand and Drive Results",
    content: [
      {
        text: "In today’s digital age, an effective online presence is crucial for business success. At Hub Be Connect, we offer tailored digital marketing strategies that help businesses increase visibility, engage their target audience, and achieve measurable results. From SEO to social media marketing, we ensure that every aspect of your digital marketing plan aligns with your business objectives.",
      },
      {
        sectionTitle: "Why Choose Our Digital Marketing Services?",
        text: "We focus on data-driven strategies that deliver real, tangible results. Our team combines creativity with analytical insights to create campaigns that resonate with your audience, build brand awareness, and drive conversions across multiple platforms.",
      },
      {
        features: [
          {
            title: "Search Engine Optimization (SEO)",
            text: "Optimize your website to rank higher in search results and attract organic traffic.",
          },
          {
            title: "Pay-Per-Click Advertising (PPC)",
            text: "Drive targeted traffic with cost-effective, highly targeted PPC campaigns on platforms like Google Ads.",
          },
          {
            title: "Social Media Marketing",
            text: "Engage your audience with compelling social media campaigns across platforms like Facebook, Instagram, and LinkedIn.",
          },
          {
            title: "Email Marketing",
            text: "Build and nurture relationships with your audience through personalized and effective email campaigns.",
          },
          {
            title: "Content Marketing",
            text: "Create valuable, relevant content to attract and retain your target audience, fostering long-term relationships.",
          },
          {
            title: "Influencer Marketing",
            text: "Collaborate with influencers to promote your brand and expand your reach in your target market.",
          },
          {
            title: "Conversion Rate Optimization (CRO)",
            text: "Improve your website’s performance and user experience to boost conversions and achieve higher ROI.",
          },
        ],
      },
    ],
  },
];

const servicesLinks = [
  {
    id: "graphic-design",
    name: "Graphic Design",
  },
  {
    id: "bookkeeping",
    name: "Bookkeeping",
  },
  {
    id: "video-editing",
    name: "Video Editing",
  },
  {
    id: "web-development",
    name: "Web Development",
  },
  {
    id: "ui-ux-design",
    name: "UI/UX Design",
  },
  {
    id: "digital-marketing",
    name: "Digital Marketing",
  },
];

function ServiceDetails() {
  const { id } = useParams();
  const service = serviceDetails.find((item) => item.id === id); // FIXED HERE

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(service, "service");

  return (
    <div>
      <div
        style={{
          backgroundImage: `linear-gradient(20deg, #082635, rgba(0,0,0,0.1)), url(${DemoImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        className="pb-24 pt-40 md:pb-52 md:pt-52"
      >
        <Container>
          <nav aria-label="Breadcrumb" className="flex">
            <ol role="list" className="flex items-center space-x-1">
              <li>
                <div>
                  <a href="#" className="text-white hover:text-white/85">
                    <HomeIcon
                      aria-hidden="true"
                      className="h-5 w-5 flex-shrink-0"
                    />
                    <span className="sr-only">Home</span>
                  </a>
                </div>
              </li>
              {pages?.map((page) => (
                <li key={page.name}>
                  <div className="flex items-center">
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      className="h-5 w-5 flex-shrink-0 text-white"
                    >
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                    <a
                      href={page.href}
                      aria-current={page.current ? "page" : undefined}
                      className="lg:font-base ml-1 text-xs text-white hover:text-white/85 sm:text-sm"
                    >
                      {page.name}
                    </a>
                  </div>
                </li>
              ))}
            </ol>
          </nav>
          <h1 className="mt-4 text-xl font-bold text-white sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
            {service?.title || "Service Not Found"}
          </h1>
        </Container>
      </div>
      <div className="py-[50px] md:py-[80px] lg:py-[120px]">
        <Container className="flex items-start">
          <div className="flex-1">
            <div className="mb-2">
              <h2 className="text-[24px] font-bold leading-snug text-custom-dark-blue sm:text-[36px] lg:text-[40px] xl:text-[44px] 2xl:text-[48px]">
                {service?.title}
              </h2>
              <p className="mt-2 text-sm text-custom-orange sm:text-base md:text-lg">
                {service?.description}
              </p>
            </div>

            {service?.content.map((section, index) => (
              <div key={index} className="">
                <div className="mb-10">
                  <h2 className="mb-2 text-xl font-semibold text-custom-dark-blue md:text-2xl">
                    {section?.sectionTitle}
                  </h2>
                  {section?.text && (
                    <p className="text-sm text-gray-700 sm:text-base">
                      {section?.text}
                    </p>
                  )}
                </div>
                {section?.features && (
                  <>
                    <div className="mt-10 grid grid-cols-1 gap-6 md:grid-cols-2">
                      {section?.features?.map((feature, idx) => (
                        <div
                          className="relative h-[100%] rounded-lg border border-[#d4d4d491] bg-gradient-box px-6 py-4 shadow-custom-box transition-all duration-300 ease-out hover:bg-white"
                          key={idx}
                        >
                          <div className="mb-3 flex h-[40px] w-[40px] items-center justify-center rounded-lg border border-dashed border-custom-orange text-lg font-bold text-custom-orange">
                            0{idx + 1}
                          </div>
                          <h3 className="mb-1 text-base font-bold capitalize text-custom-dark-blue">
                            {feature?.title}:
                          </h3>
                          <p className="text-xs text-gray-700 md:text-sm">
                            {feature?.text}
                          </p>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>

          <div className="ml-10 w-[30%]">
            <div className="rounded-lg bg-white px-5 py-8">
              {servicesLinks?.map((link) => (
                <Link
                  href={link.id}
                  className="flex items-center justify-between px-[9px] py-[8px] text-base leading-[30px] text-gray-700 transition-all duration-200 ease-in-out hover:text-custom-orange"
                >
                  {link.name}
                  <FaArrowRight />
                </Link>
              ))}
            </div>
            <div className="bg-bg-quote-box bg-no-rep relative mt-10 flex flex-col items-center justify-center overflow-hidden rounded-lg bg-cover bg-center px-[30px] py-[80px] after:absolute after:bottom-0 after:left-0 after:right-0 after:top-0 after:-z-0 after:bg-[#071a3eb3] after:content-['']">
              <h3 className="sm2:text-[26px] relative z-10 mb-5 text-center text-[24px] leading-tight text-white sm:text-[36px]">
                Have Any Query?
              </h3>
              <Link
                href="#"
                className="relative z-10 text-sm font-extrabold hover:text-custom-orange sm:text-xl"
              >
                <Button
                  className={
                    "flex items-center bg-custom-orange text-base font-normal uppercase text-white hover:bg-custom-dark-blue"
                  }
                >
                  Get a Quote
                  <FaArrowRight className="ml-2" />
                </Button>
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ServiceDetails;

import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import { FiMenu } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import Logo from "../../assets/logo.svg";

const services = [
  { id: "graphic-design", name: "Graphic Design" },
  { id: "bookkeeping", name: "Bookkeeping" },
  { id: "video-editing", name: "Video Editing" },
  { id: "web-development", name: "Web Development" },
  { id: "ui-ux-design", name: "UI/UX Design" },
  { id: "digital-marketing", name: "Digital Marketing" },
];

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const [activeSection, setActiveSection] = useState("home");
  const [openService, setOpenService] = useState(false);
  const [openCourse, setOpenCourse] = useState(false);

  const handleServiceClick = (id) => {
    navigate(`/service-details/${id}`);
    setOpenService(false); // Close the popover
  };

  const handleCourseClick = (id) => {
    navigate(`/course-details/${id}`);
    setOpenCourse(false); // Close the popover
  };

  const handleNavClick = (path) => {
    if (path === "about" || path === "courses") {
      // Direct navigation to the page for About and Courses
      navigate(`/${path}`);
    } else {
      setActiveSection(path);
      if (!isHomePage) {
        navigate("/"); // Go to the home page
        setTimeout(() => {
          scroller.scrollTo(path, {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
            offset: -50,
            spy: true,
          });
        }, 500);
      } else {
        scroller.scrollTo(path, {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
          offset: -50,
        });
      }
    }
  };

  useEffect(() => {
    if (!isHomePage) {
      setActiveSection("");
    }
  }, [location]);

  return (
    <div className="relative z-20 mx-auto w-full max-w-7xl bg-white/60 px-4 py-4 backdrop-blur-md sm:px-6 md:px-8 lg:px-8 xl:w-[80%] xl:rounded-full xl:border xl:border-[#d4d4d491] xl:shadow-lg">
      <nav className="navbar relative z-50 flex items-center justify-between">
        <a onClick={() => handleNavClick("home")} className="cursor-pointer">
          <img src={Logo} alt="logo" className="w-[100px] sm:w-[140px]" />
        </a>

        {/* Desktop Navigation */}
        <div className="hidden gap-x-8 lg:flex">
          <a
            onClick={() => handleNavClick("home")}
            className={`cursor-pointer hover:text-orange-500 ${activeSection === "home" ? "text-orange-500" : ""}`}
          >
            Home
          </a>
          <a
            onClick={() => handleNavClick("about")}
            className={`cursor-pointer hover:text-orange-500 ${activeSection === "about" ? "text-orange-500" : ""}`}
          >
            About
          </a>

          {/* Services Dropdown */}
          <Popover className="relative">
            {({ open }) => (
              <>
                <PopoverButton
                  className={`flex items-center outline-none hover:text-orange-500 ${location.pathname.startsWith("/service-details") ? "text-orange-500" : ""}`}
                >
                  Services
                  <FaChevronDown
                    className={`ml-2 text-sm transition duration-300 ${open ? "rotate-180" : ""}`}
                  />
                </PopoverButton>
                <PopoverPanel className="absolute left-0 mt-8 w-64 overflow-hidden rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-2">
                    {services.map((service) => (
                      <button
                        key={service.id}
                        className={`block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-blue-100/30 ${location.pathname.endsWith(service.id) ? "text-orange-500" : ""}`}
                        onClick={() => handleServiceClick(service.id)}
                      >
                        {service.name}
                      </button>
                    ))}
                  </div>
                </PopoverPanel>
              </>
            )}
          </Popover>

          <a
            onClick={() => handleNavClick("testimonial")}
            className={`cursor-pointer hover:text-orange-500 ${activeSection === "testimonial" ? "text-orange-500" : ""}`}
          >
            Testimonial
          </a>

          <a
            onClick={() => handleNavClick("teams")}
            className={`cursor-pointer hover:text-orange-500 ${activeSection === "teams" ? "text-orange-500" : ""}`}
          >
            Teams
          </a>

          <a
            onClick={() => handleNavClick("courses")}
            className={`cursor-pointer hover:text-orange-500 ${activeSection === "courses" ? "text-orange-500" : ""}`}
          >
            Courses
          </a>
        </div>

        <div className="flex items-center">
          {/* Contact Button */}
          <div>
            <button className="fontSuse mr-2 rounded-full bg-custom-black px-3 py-2 text-sm uppercase text-white hover:bg-custom-dark-blue sm:mr-4 sm:px-6 sm:text-base">
              GET IN TOUCH
            </button>
          </div>
          {/* Mobile Navigation */}
          <div className="lg:hidden">
            <MobileNavigation />
          </div>
        </div>
      </nav>
    </div>
  );
}

function MobileNavigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const [activeSection, setActiveSection] = useState("home");

  const handleServiceClick = (id) => {
    navigate(`/service-details/${id}`);
  };

  const handleCourseClick = (id) => {
    navigate(`/course-details/${id}`);
  };

  const handleNavClick = (path) => {
    if (path === "about" || path === "courses") {
      // Direct navigation to the page for About and Courses
      navigate(`/${path}`);
    } else {
      setActiveSection(path);
      if (!isHomePage) {
        navigate("/"); // Go to the home page
        setTimeout(() => {
          scroller.scrollTo(path, {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
            offset: -50,
          });
        }, 500);
      } else {
        scroller.scrollTo(path, {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
          offset: -50,
        });
      }
    }
  };

  useEffect(() => {
    if (!isHomePage) {
      setActiveSection("");
    }
  }, [location]);

  return (
    <Popover>
      <PopoverButton className="relative z-10 flex h-8 w-8 items-center justify-center focus:outline-none" aria-label="Toggle Navigation">
        {({ open }) => <FiMenu className="h-6 w-6" />}
      </PopoverButton>
      <Popover.Panel className="absolute inset-x-0 top-full mt-6 flex origin-top transform flex-col rounded-2xl bg-white py-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5 transition duration-150 ease-in-out">
        <a onClick={() => handleNavClick("home")} className={`cursor-pointer hover:text-orange-500 ${activeSection === "home" ? "text-orange-500" : ""} block w-full px-4 py-2 text-base hover:bg-blue-100/30`}>Home</a>
        <a onClick={() => handleNavClick("about")} className={`cursor-pointer hover:text-orange-500 ${activeSection === "about" ? "text-orange-500" : ""} block w-full px-4 py-2 text-base hover:bg-blue-100/30`}>About</a>
        
        {/* Services Dropdown */}
        <Popover className="relative">
          {({ open }) => (
            <>
              <PopoverButton className={`flex w-full items-center px-4 py-2 text-base outline-none hover:text-orange-500 ${location.pathname.startsWith("/service-details") ? "text-orange-500" : ""}`}>
                Services
                <FaChevronDown className={`ml-2 text-sm transition duration-300 ${open ? "rotate-180" : ""}`} />
              </PopoverButton>
              <PopoverPanel className="w-[99%] overflow-hidden">
                <div className="py-2">
                  {services.map((service) => (
                    <button
                      key={service.id}
                      className={`block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-blue-100/30 ${location.pathname.endsWith(service.id) ? "text-orange-500" : ""}`}
                      onClick={() => handleServiceClick(service.id)}
                    >
                      {service.name}
                    </button>
                  ))}
                </div>
              </PopoverPanel>
            </>
          )}
        </Popover>

        <a onClick={() => handleNavClick("testimonial")} className={`cursor-pointer hover:text-orange-500 ${activeSection === "testimonial" ? "text-orange-500" : ""} block w-full px-4 py-2 text-base hover:bg-blue-100/30`}>Testimonial</a>

        <a onClick={() => handleNavClick("teams")} className={`cursor-pointer hover:text-orange-500 ${activeSection === "teams" ? "text-orange-500" : ""} block w-full px-4 py-2 text-base hover:bg-blue-100/30`}>Teams</a>

        <a onClick={() => handleNavClick("courses")} className={`cursor-pointer hover:text-orange-500 ${activeSection === "courses" ? "text-orange-500" : ""} block w-full px-4 py-2 text-base hover:bg-blue-100/30`}>Courses</a>
      </Popover.Panel>
    </Popover>
  );
}

export default Navbar;

import React, { useState } from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaBehance,
  FaYoutube,
  FaCopy,
  FaArrowLeft,
  FaArrowRight,
} from "react-icons/fa";
import { motion } from "framer-motion";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

import TeamImg from "../assets/teams/team.webp";
import CooImg from "../assets/teams/coo.webp";
import CeoImg from "../assets/teams/ceo.webp";
import YasiraImg from "../assets/teams/yasira.webp";
import AftabImg from "../assets/teams/aftab.webp";

import { Container } from "./common/Container";
import SwirlyDoodle from "./common/SwirlyDoodle";
import { Button } from "./common/Button";

const teamMembers = [
  {
    name: "Sohail Malik",
    role: "Chief Executive Officers",
    image: CeoImg,
    socialLinks: { facebook: "#", twitter: "#", instagram: "#" },
  },
  {
    name: "Rifaqat Ali Khan",
    role: "Chief Operating Officer",
    image: CooImg,
    socialLinks: { facebook: "#", behance: "#" },
  },
  {
    name: "Aftab Jahangir",
    role: "Managing Director",
    image: AftabImg,
    socialLinks: { facebook: "#", youtube: "#" },
  },
  {
    name: "Yasira Mustafa",
    role: "Social Media Manager",
    image: YasiraImg,
    socialLinks: { instagram: "#" },
  },
  {
    name: "Samira Aziz",
    role: "Graphic Designer",
    image: TeamImg,
    socialLinks: { twitter: "#" },
  },
  {
    name: "Humaira Zahir",
    role: "Finance Manager",
    image: TeamImg,
    socialLinks: { facebook: "#" },
  },
];

const iconMap = {
  facebook: <FaFacebookF />,
  twitter: <FaTwitter />,
  instagram: <FaInstagram />,
  behance: <FaBehance />,
  youtube: <FaYoutube />,
};

const iconVariants = {
  hidden: { y: -20, opacity: 0 },
  visible: (i) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: i * 0.1,
      duration: 0.3,
    },
  }),
};

const Teams = () => {
  return (
    <Container className="relative">
      <div className="flex flex-col items-center justify-center md:flex-row md:justify-between">
        <div className="mb-10">
          <span className="flex items-center fontSuse justify-center text-base font-medium text-custom-orange md:mb-3 mb-2 md:justify-start">
            <FaCopy className="mr-2" /> Our Best Review’s
          </span>
          <h2 className="text-[24px] font-bold leading-snug text-custom-dark-blue sm:text-[36px] lg:text-[40px] xl:text-[44px] 2xl:text-[48px]">
            Customer’s Feedback
          </h2>
        </div>

        <div className="mb-6 ml-2 hidden justify-center gap-4 md:flex">
          <Button
            className={
              "bg-custom-black uppercase rounded-md py-3 text-white hover:bg-custom-dark-blue"
            }
          >
            <FaArrowLeft className="mr-2 h-4 w-4" />
            PREV
          </Button>
          <Button
            className={
              "bg-custom-black uppercase rounded-md py-3 text-white hover:bg-custom-dark-blue"
            }
          >
            NEXT <FaArrowRight className="ml-3 text-white" />
          </Button>
        </div>
      </div>
      <Swiper
        slidesPerView={1.1}
        spaceBetween={10}
        // autoplay={{
        //   delay: 4000,
        //   disableOnInteraction: false,
        // }}
        loop={true}
        breakpoints={{
          600: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          700: {
            slidesPerView: 2.2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3.1,
            spaceBetween: 10,
          },
          1300: {
            slidesPerView: 3.2,
            spaceBetween: 20,
          },
        }}
      >
        {teamMembers.map((member, index) => (
          <SwiperSlide key={index}>
            <HoverAnimationCard member={member} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

const HoverAnimationCard = ({ member }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative z-20 flex h-full cursor-pointer flex-col items-start overflow-hidden rounded-3xl border border-[#d4d4d491] bg-gradient-box p-3 shadow-custom-box"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="mb-4 h-[300px] w-full overflow-hidden rounded-2xl">
        <img
          src={member.image}
          alt={`${member.name} - ${member.position}`}
          width={"100%"}
          height={"100%"}
          className="object-cover object-center"
        />
      </div>
      <div className="w-full pb-2 text-center">
        <h3 className="text-lg font-bold text-custom-dark-blue">
          {member.name}
        </h3>
        <p className="fontSuse text-base text-custom-orange">{member.role}</p>
      </div>
      <div
        className={`absolute left-0 right-0 top-[245px] mt-auto flex w-full items-center justify-center gap-2 opacity-0 transition-opacity duration-300 ${isHovered ? "opacity-100" : ""}`}
      >
        {Object.keys(member.socialLinks).map((key, i) => (
          <motion.a
            key={i}
            href={member.socialLinks[key]}
            target="_blank"
            rel="noopener noreferrer"
            initial="hidden"
            animate={isHovered ? "visible" : "hidden"}
            custom={i}
            variants={iconVariants}
            className="flex h-[40px] w-[40px] items-center justify-center rounded-md bg-black/20 text-xl text-white backdrop-blur-sm"
          >
            {iconMap[key]}
          </motion.a>
        ))}
      </div>
    </div>
  );
};

export default Teams;

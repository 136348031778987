import { HomeIcon } from "@heroicons/react/20/solid";
import DemoImg from "../../assets/demo.avif";
import { Container } from "../../components/common/Container";
import Contact from "../../components/contact";
import ListCourses from "./list-courses";

const pages = [{ name: "Courses", href: "#", current: false }];

function Courses() {
  return (
    <div>
      <div
        style={{
          backgroundImage: `linear-gradient(20deg, #082635, rgba(0,0,0,0.1)), url(${DemoImg})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
        }}
        className="pb-24 pt-40 md:pb-52 md:pt-52"
      >
        <Container>
          <nav aria-label="Breadcrumb" className="flex">
            <ol role="list" className="flex items-center space-x-1">
              <li>
                <div>
                  <a href="#" className="text-white hover:text-white/85">
                    <HomeIcon
                      aria-hidden="true"
                      className="h-5 w-5 flex-shrink-0"
                    />
                    <span className="sr-only">Home</span>
                  </a>
                </div>
              </li>
              {pages?.map((page) => (
                <li key={page.name}>
                  <div className="flex items-center">
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      className="h-5 w-5 flex-shrink-0 text-white"
                    >
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                    <a
                      href={page.href}
                      aria-current={page.current ? "page" : undefined}
                      className="lg:font-base ml-1 text-xs text-white hover:text-white/85 sm:text-sm"
                    >
                      {page.name}
                    </a>
                  </div>
                </li>
              ))}
            </ol>
          </nav>
          <h1 className="mt-4 text-xl font-bold text-white sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
            Skill Development Program
          </h1>
        </Container>
      </div>
      <div className="py-10 md:py-20">
        <Container>
          <div className="head mb-10 md:mb-20">
            <p className="text-justify text-sm text-custom-orange sm:text-base md:text-lg">
              Unlock Your Potential with Us
            </p>
            <h2 className="mb-5 text-[24px] font-bold leading-snug text-custom-dark-blue sm:text-[36px] lg:text-[40px] xl:text-[44px] 2xl:text-[48px]">
              Empowering the Future with Quality IT skills
            </h2>

            <p className="mb-4 text-xs text-gray-600 sm:text-sm md:text-base">
              Over the years, <strong>Hub Be Connect</strong> has dedicated
              itself to equipping individuals with essential IT skills. Since
              2023, we have trained over 10,000 students across 10+
              in-demand IT disciplines. With the growing demand for skilled
              professionals, <strong>Hub Be Connect</strong> is committed to
              expanding its programs with renewed passion and excellence.
            </p>

            <p className="mb-4 text-xs text-gray-600 sm:text-sm md:text-base">
              Furthermore, <strong>Hub Be Connect</strong> actively collaborates
              with government and non-government organizations to implement
              various skill development initiatives, ensuring a brighter future
              for aspiring IT professionals.
            </p>
          </div>
          <div>
            <h2 className="mb-8 text-center text-[24px] font-bold leading-snug text-custom-dark-blue sm:text-[36px] lg:text-[40px]">
            Explore Our Programs & Courses
            </h2>

            <ListCourses />
          </div>
        </Container>
      </div>

    </div>
  );
}

export default Courses;

import React from "react";
import { Container } from "./common/Container";
import Logo from "../assets/white logo.svg";
import { EnvelopeIcon, MapPinIcon, PhoneIcon } from "@heroicons/react/24/solid";

import {
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
} from "react-icons/fa6";
import { FaMailBulk } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div className="relative bg-custom-dark-blue pt-20">
        {/* <div className="zx">
          <div class="custom-shape-divider-bottom-1725395112">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                opacity=".25"
                class="shape-fill"
              ></path>
              <path
                d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                opacity=".5"
                class="shape-fill"
              ></path>
              <path
                d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                class="shape-fill"
              ></path>
            </svg>
          </div>
        </div> */}
        <Container
          className={
            "flex flex-wrap items-start justify-between pb-10 sm:pb-20 md:flex-nowrap"
          }
        >
          <div className="mb-6 w-full md:mb-0 md:mr-8 md:w-[450px]">
            <img
              src={Logo}
              alt="logo not found"
              className="w-[160px] sm:w-[260px]"
            />
            <p className="mb-4 mt-4 max-w-[500px] text-sm font-light tracking-wide text-white">
            Hub Be-Connect is a dynamic tech start-up based in Chitral, dedicated to equipping individuals and businesses with essential freelancing skills. We offer short courses and diplomas in areas like bookkeeping and graphic design, empowering professionals to thrive in the digital economy.
            </p>
            <ul className="mt-6 flex items-center gap-x-2 p-0">
              <li>
                <a
                  href="mailto:"
                  className="group flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-md bg-white transition duration-300"
                >
                  <FaEnvelope className="text-[22px] text-custom-dark-blue transition duration-300 group-hover:text-custom-orange" />
                </a>
              </li>
              <li>
                <a
                  href="https://web.facebook.com/hubbeconnect/"
                  className="group flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-md bg-white transition duration-300"
                >
                  <FaFacebook className="text-[22px] text-custom-dark-blue transition duration-300 group-hover:text-custom-orange" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/beconnect1/"
                  className="group flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-md bg-white transition duration-300"
                >
                  <FaInstagram className="text-[22px] text-custom-dark-blue transition duration-300 group-hover:text-custom-orange" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.hub-beconnect-smc-pvt-ltd"
                  className="group flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-md bg-white transition duration-300"
                >
                  <FaLinkedin className="text-[22px] text-custom-dark-blue transition duration-300 group-hover:text-custom-orange" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.hub-beconnect-smc-pvt-ltd"
                  className="group flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-md bg-white transition duration-300"
                >
                  <FaTiktok className="text-[22px] text-custom-dark-blue transition duration-300 group-hover:text-custom-orange" />
                </a>
              </li>
            </ul>
          </div>

          <ul className="mb-4 pt-2 sm:mb-0">
            <h3 className="mb-6 text-lg font-medium text-white sm:text-xl">
              Discover
            </h3>
            <li className="mb-3 text-sm text-gray-300 transition duration-300 hover:text-white">
              <a href="#">About us</a>
            </li>
            <li className="mb-3 text-sm text-gray-300 transition duration-300 hover:text-white">
              <a href="#">Services</a>
            </li>
            <li className="mb-3 text-sm text-gray-300 transition duration-300 hover:text-white">
              <a href="#">Our team</a>
            </li>
            <li className="mb-3 text-sm text-gray-300 transition duration-300 hover:text-white">
              <a href="#">Contact us</a>
            </li>
          </ul>
          <ul className="mb-4 pt-2 sm:mb-0">
            <h3 className="mb-6 text-lg font-medium text-white sm:text-xl">
              Support
            </h3>
            <li className="mb-3 text-sm text-gray-300 transition duration-300 hover:text-white">
              <a href="#">Contact Us </a>
            </li>
            <li className="mb-3 text-sm text-gray-300 transition duration-300 hover:text-white">
              <a href="#">Privacy Policy</a>
            </li>
          </ul>
          {/* 
          <ul className="flex-1 pt-2 xl:max-w-[350px]">
            <h3 className="mb-4 text-lg font-medium text-white sm:text-xl">
              Office Maps
            </h3>

            <div className="w-full h-auto rounded-md overflow-hidden">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3231.240217589241!2d74.35406937564578!3d35.91661977251225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38e649479c6a588d%3A0xc18679d793fc7a0f!2sCode%20Arena!5e0!3m2!1sen!2s!4v1736178544817!5m2!1sen!2s"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                className="rounded-md overflow-hidden w-full h-auto"
              ></iframe>
            </div>
            <li className="mb-4 text-sm text-gray-300 transition duration-300 hover:text-white">
              <a href="tel:+923400180008" className="flex items-center">
                <PhoneIcon className="mr-4 w-6 text-custom-orange" />
                <span className="flex-1">(+92) 3400180008</span>
              </a>
            </li>
            <li className="mb-4 text-sm text-gray-300 transition duration-300 hover:text-white">
              <a
                href="mailto:hubbeconnect7@gmail.com"
                className="flex items-center"
              >
                <EnvelopeIcon className="mr-4 w-6 text-custom-orange" />
                <span className="flex-1">hubbeconnect7@gmail.com</span>
              </a>
            </li>
            <li className="mb-4 text-xs text-gray-300 transition duration-300 hover:text-white">
              <a href="#" className="flex items-center">
                <MapPinIcon className="mr-4 w-6 text-custom-orange" />
                <span className="flex-1">
                  Hub Be Connect, Garamcashma Road Balach payeen, lower chitral,
                  pakistan
                </span>
              </a>
            </li>
          </ul> */}
        </Container>
        <div className="after:content-[' '] relative py-6 after:absolute after:left-[10%] after:top-0 after:w-[80%] after:border after:border-dashed after:border-gray-500">
          <Container>
            <p className="text-center text-xs text-white">
              2025 © HUB BE CONNECT SMC (PVT) LTD. All rights reserved.
            </p>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Footer;

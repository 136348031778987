import { HomeIcon } from "@heroicons/react/20/solid";
import DemoImg from "../../assets/demo.avif";
import { Container } from "../../components/common/Container";
import Contact from "../../components/contact";

import DemoImgTwo from "../../assets/demo2.jfif";
import DemoImgThree from "../../assets/demo3.jfif";

const pages = [{ name: "About", href: "#", current: false }];

function AboutDetails() {
  return (
    <div>
      <div
        style={{
          backgroundImage: `linear-gradient(20deg, #082635, rgba(0,0,0,0.1)), url(${DemoImg})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
        }}
        className="pb-24 pt-40 md:pb-52 md:pt-52"
      >
        <Container>
          {/* <nav aria-label="Breadcrumb" className="flex">
            <ol role="list" className="flex items-center space-x-1">
              <li>
                <div>
                  <a href="#" className="text-white hover:text-white/85">
                    <HomeIcon
                      aria-hidden="true"
                      className="h-5 w-5 flex-shrink-0"
                    />
                    <span className="sr-only">Home</span>
                  </a>
                </div>
              </li>
              {pages?.map((page) => (
                <li key={page.name}>
                  <div className="flex items-center">
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      className="h-5 w-5 flex-shrink-0 text-white"
                    >
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                    <a
                      href={page.href}
                      aria-current={page.current ? "page" : undefined}
                      className="lg:font-base ml-1 text-xs text-white hover:text-white/85 sm:text-sm"
                    >
                      {page.name}
                    </a>
                  </div>
                </li>
              ))}
            </ol>
          </nav> */}
          <h1 className="mt-4 text-xl font-bold text-white sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
            About Our Company
          </h1>
        </Container>
      </div>
      <div className="py-[50px] md:py-[80px] lg:py-[120px]">
        <Container>
          <div className="head mb-10 md:mb-10">
            <div>
              <h2 className="mb-5 text-[24px] font-bold leading-snug text-custom-dark-blue sm:text-[36px] lg:text-[40px] xl:text-[44px] 2xl:text-[48px]">
                We are digital creative agency in Chitral
              </h2>
              <p className="mb-4 text-xs text-gray-600 sm:text-sm md:text-base">
                Hub Be-Connect is a start-up tech company in the Chitral
                District. since the company stands on the notion of digital
                skills as an academic initiative, we are offering short courses
                & diploma courses.
              </p>
              <p className="mb-4 text-xs text-gray-600 sm:text-sm md:text-base">
                Hub Beconnect is dedicated to equipping individuals and
                organizations with the vital freelancing skills necessary to
                thrive in the modern digital landscape. Founded on the
                principles of innovation, excellence, and community, we provide
                top-tier training in essential areas such as bookkeeping and
                graphic designing.
              </p>
              <p className="mb-4 text-xs text-gray-600 sm:text-sm md:text-base">
                At Hub Beconnect, we are committed to exceptional service and
                integrity.
              </p>
              <p className="mb-4 text-xs text-gray-600 sm:text-sm md:text-base">
                Our team of experienced professionals understands the unique
                needs of our clients, offering tailored solutions that help them
                navigate complexity, seize opportunities, and excel in their
                careers. By fostering a supportive community of skilled
                professionals, we enable collaboration, knowledge sharing, and
                mutual growth.
              </p>
              <p className="mb-4 text-xs text-gray-600 sm:text-sm md:text-base">
                Join us at Hub Beconnect and take the next step in your
                professional journey. Together, we can achieve excellence and
                strategic success
              </p>
            </div>
          </div>
          <div className="flex h-full flex-wrap justify-between">
            <div className="mb-6 max-h-[100%] w-full sm:mb-0 sm:w-[67%]">
              <img
                src={DemoImgTwo}
                alt="about img"
                className="h-full w-full rounded-2xl object-cover shadow-lg"
              />
            </div>
            <div className="max-h-[100%] w-full sm:w-[30%]">
              <img
                src={DemoImgThree}
                alt="about img"
                className="h-full w-full rounded-2xl object-cover shadow-lg"
              />
            </div>
          </div>

          <div className="flex justify-between space-x-6 py-10">
            <div className="mb-6 w-full sm:mb-0">
              <span className="mb-2 flex h-[25px] w-[25px] items-center justify-center rounded-md border border-dashed border-custom-orange text-sm font-bold text-custom-orange">
                01
              </span>
              <h3 className="mb-2 text-lg font-semibold text-custom-dark-blue sm:text-xl md:text-2xl">
                Our <span className="fontSuse text-custom-orange">Vision</span>
              </h3>
              <p className="text-light text-xs text-gray-600 sm:text-sm">
                To be the leading partner in empowering individuals and
                businesses through innovative skill development, driving
                sustainable growth and lasting impact.
              </p>
            </div>
            <div className="mb-6 w-full sm:mb-0">
              <span className="mb-2 flex h-[25px] w-[25px] items-center justify-center rounded-md border border-dashed border-custom-orange text-sm font-bold text-custom-orange">
                02
              </span>
              <h3 className="mb-2 text-lg font-semibold text-custom-dark-blue sm:text-xl md:text-2xl">
                Our <span className="fontSuse text-custom-orange">Mission</span>
              </h3>
              <p className="text-light text-xs text-gray-600 sm:text-sm">
                We aim to bridge the skills gap by providing accessible,
                top-quality training, fostering a community of skilled
                professionals ready to excel in today’s digital landscape.
              </p>
            </div>
            <div className="mb-6 w-full sm:mb-0">
              <span className="mb-2 flex h-[25px] w-[25px] items-center justify-center rounded-md border border-dashed border-custom-orange text-sm font-bold text-custom-orange">
                03
              </span>
              <h3 className="mb-2 text-lg font-semibold text-custom-dark-blue sm:text-xl md:text-2xl">
                Our <span className="fontSuse text-custom-orange">Goals</span>
              </h3>

              <p className="text-light text-xs text-gray-600 sm:text-sm">
              We aim to empower individuals with freelancing skills, provide market-driven training, ensure quality, drive growth, and build a supportive professional community.
              </p>
              {/* <ul>
                <li className="text-light mb-2 text-xs text-gray-600 sm:text-sm">
                  <span className="font-medium text-custom-black">Empowerment:</span> Equip individuals with vital freelancing skills.
                </li>
                <li className="text-light mb-2 text-xs text-gray-600 sm:text-sm">
                  <span className="font-medium text-custom-black">Innovation:</span> Offer cutting-edge training aligned with market needs.
                </li>
                <li className="text-light mb-2 text-xs text-gray-600 sm:text-sm">
                  <span className="font-medium text-custom-black">Excellence:</span> Uphold quality and integrity in all services.
                </li>
                <li className="text-light mb-2 text-xs text-gray-600 sm:text-sm">
                  <span className="font-medium text-custom-black">Growth:</span> Drive sustainable career and business development.
                </li>
                <li className="text-light mb-2 text-xs text-gray-600 sm:text-sm">
                  <span className="font-medium text-custom-black">Community:</span> Build a network of professionals for collaboration and success.
                </li>
              </ul> */}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default AboutDetails;

import React from "react";
import { Container } from "./common/Container";
import { Button } from "./common/Button";

import aboutImg from "../assets/about-img-1.webp";
import DemoImgTwo from "../assets/demo2.jfif";
import DemoImgThree from "../assets/demo3.jfif";

import AboutImg from "../assets/about.webp";
import SwirlyDoodle from "./common/SwirlyDoodle";

// const AboutSection = () => {
//   return (
//     <>
//       <Container>
//         <div className="head mb-10 md:mb-20">
//           <h2 className="mb-6 text-2xl font-semibold tracking-tight text-custom-dark-blue lg:text-3xl">
//             We are digital creative{" "}
//             <span className="relative whitespace-nowrap">
//               <SwirlyDoodle className="absolute left-[10%] top-1/2 h-[1em] w-[80%] fill-blue-300" />
//               <span className="fontSuse relative text-custom-orange">
//                 agency in Chitral
//               </span>
//             </span>
//           </h2>
//           <p className="mb-4 text-xs text-gray-600 sm:text-sm md:text-base">
//             Hub Be-Connect is a dynamic tech start-up based in Chitral,
//             dedicated to equipping individuals and businesses with essential
//             freelancing skills. We offer short courses and diplomas in areas
//             like bookkeeping and graphic design, empowering professionals to
//             thrive in the digital economy.
//           </p>
//           <p className="mb-4 text-xs text-gray-600 sm:text-sm md:text-base">
//             Rooted in innovation and community, we foster growth through
//             tailored training, collaboration, and knowledge sharing. Our expert
//             team is committed to integrity, excellence, and creating
//             opportunities that drive success.
//           </p>
//         </div>

//         <div className="flex flex-wrap justify-between h-full">
//           <div className="mb-6 w-full sm:mb-0 sm:w-[67%] max-h-[100%]">
//             <img
//               src={DemoImgTwo}
//               alt="about img"
//               className="rounded-2xl object-cover shadow-lg w-full h-full"
//             />
//           </div>
//           <div className="w-full sm:w-[30%] max-h-[100%]">
//             <img
//               src={DemoImgThree}
//               alt="about img"
//               className="rounded-2xl object-cover shadow-lg w-full h-full"
//             />
//           </div>
//         </div>

//         <div className="after:content-[' '] relative flex flex-wrap items-end pb-10 after:absolute after:bottom-0 after:left-[15%] after:h-[2px] after:w-[70%] after:border after:border-dashed after:border-[#d4d4d491]">
//           <div className="mb-6 w-full sm:mb-0 sm:flex-1">
//             <span className="mb-2 flex h-[25px] w-[25px] items-center justify-center rounded-md border border-dashed border-custom-orange text-sm font-bold text-custom-orange">
//               01
//             </span>
//             <h3 className="mb-2 text-lg font-semibold text-custom-dark-blue sm:text-xl md:text-2xl">
//               Our <span className="fontSuse text-custom-orange">vision</span>
//             </h3>
//             <p className="mb-2 text-xs text-gray-600 sm:text-sm">
//               At Hub Beconnect, our vision is to be the premier partner in
//               empowering organizations worldwide to achieve excellence and
//               strategic success. We strive to redefine the boundaries of
//               freelancing skills development by offering innovative solutions
//               that drive sustainable growth, maximize profitability, and create
//               lasting value for our clients.
//             </p>
//             <p className="text-xs text-gray-600 sm:text-sm">
//               Through our unwavering commitment to exceptional service,
//               integrity, and a deep understanding of our clients' needs, we aim
//               to become trusted advisors. We enable businesses to navigate
//               complexity, seize opportunities, and thrive in a rapidly evolving
//               global economy. By equipping individuals with vital freelancing
//               skills such as bookkeeping and graphic designing, we ensure they
//               are prepared to excel in today's competitive landscape.
//             </p>
//           </div>
//           <div className="ml-auto w-full overflow-hidden rounded-lg border border-[#d4d4d491] shadow-custom-box sm:ml-4 sm:w-[220px] md:ml-8 md:w-[300px] xl:ml-14">
//             <img
//               src={AboutImg}
//               alt="img not found"
//               width={"100%"}
//               className="rounded-md object-cover object-center"
//             />
//           </div>
//         </div>

//         <div className="after:content-[' '] relative mt-10 flex flex-wrap items-end pb-10 after:absolute after:bottom-0 after:left-[15%] after:h-[2px] after:w-[70%] after:border after:border-dashed after:border-[#d4d4d491]">
//           <div className="order-2 ml-auto w-full overflow-hidden rounded-lg border border-[#d4d4d491] shadow-custom-box sm:order-1 sm:mr-4 sm:w-[220px] md:mr-8 md:w-[300px] xl:mr-14">
//             <img
//               src={AboutImg}
//               alt="img not found"
//               width={"100%"}
//               className="rounded-md object-cover object-center"
//             />
//           </div>
//           <div className="order-1 mb-6 w-full sm:order-2 sm:mb-0 sm:flex-1">
//             <span className="mb-2 flex h-[25px] w-[25px] items-center justify-center rounded-md border border-dashed border-custom-orange text-sm font-bold text-custom-orange">
//               02
//             </span>
//             <h3 className="mb-2 text-lg font-semibold text-custom-dark-blue sm:text-xl md:text-2xl">
//               Our <span className="fontSuse text-custom-orange">mission</span>
//             </h3>
//             <p className="mb-2 text-xs text-gray-600 sm:text-sm">
//               At Hub Beconnect, our mission is to empower individuals and
//               businesses by providing top-quality training in essential
//               freelancing skills such as bookkeeping and graphic designing.
//             </p>
//             <p className="mb-2 text-xs text-gray-600 sm:text-sm">
//               We are committed to fostering a community of skilled professionals
//               who can excel in the digital economy, driving growth and success
//               for themselves and their clients.
//             </p>
//             <p className="mb-2 text-xs text-gray-600 sm:text-sm">
//               Through innovative and accessible learning solutions, we aim to
//               bridge the skills gap and create opportunities for sustainable
//               career development.
//             </p>
//             <p className="mb-2 text-xs text-gray-600 sm:text-sm">
//               Through our collaborative approach, we strive to be the trusted
//               partner that empowers businesses to thrive in a dynamic and
//               competitive business landscape.
//             </p>
//             <p className="text-xs text-gray-600 sm:text-sm">
//               Together, we are committed to creating lasting value, fostering
//               long-term relationships, and contributing to the success of our
//               youth and the communities they serve.
//             </p>
//           </div>
//         </div>

//         <div className="after:content-[' '] relative mt-10 flex flex-wrap items-end pb-10 after:absolute after:bottom-0 after:left-[15%] after:h-[2px] after:w-[70%] after:border after:border-dashed after:border-[#d4d4d491]">
//           <div className="mb-6 w-full sm:mb-0 sm:flex-1">
//             <span className="mb-2 flex h-[25px] w-[25px] items-center justify-center rounded-md border border-dashed border-custom-orange text-sm font-bold text-custom-orange">
//               03
//             </span>
//             <h3 className="mb-2 text-lg font-semibold text-custom-dark-blue sm:text-xl md:text-2xl">
//               Our <span className="fontSuse text-custom-orange">goal</span>
//             </h3>
//             <p className="text-xs text-gray-600 sm:text-sm lg:text-base">
//               Lorem ipsum dolor sit amet consectetur adipisicing elit.
//               Laboriosam quia quas eligendi delectus? Minus illum veritatis
//               dignissimos quis culpa necessitatibus eaque iure officia tempore
//               quasi similique facilis praesentium, autem illo?
//             </p>
//           </div>
//           <div className="ml-auto w-full overflow-hidden rounded-lg border border-[#d4d4d491] shadow-custom-box sm:ml-4 sm:w-[220px] md:ml-8 md:w-[300px] xl:ml-14">
//             <img
//               src={AboutImg}
//               alt="img not found"
//               width={"100%"}
//               className="rounded-md object-cover object-center"
//             />
//           </div>
//         </div>
//       </Container>
//     </>
//   );
// };

import { FaArrowRight, FaCheckCircle, FaCopy } from "react-icons/fa";
import { Link } from "react-router-dom";

const AboutSection = () => {
  return (
    <Container>
      <div className="flex flex-col items-center lg:flex-row lg:space-x-10 xl:space-x-16">
        <div className="order-2 flex flex-col justify-center lg:order-1 lg:w-7/12 xl:w-1/2">
          <div className="border-b border-dashed border-[#d4d4d491] pb-4">
            <span className="fontSuse mb-2 flex items-center text-base font-medium text-custom-orange md:mb-3">
              <FaCopy className="mr-2" /> About Our Company
            </span>
            <h2 className="mb-5 text-[24px] font-bold leading-snug text-custom-dark-blue sm:text-[36px] lg:text-[40px] xl:text-[44px] 2xl:text-[48px]">
              We are digital creative agency in Chitral
            </h2>
            <p className="mb-2 text-xs font-light text-justify text-gray-600 sm:text-sm">
              Hub Be-Connect is a dynamic tech start-up based in Chitral,
              dedicated to equipping individuals and businesses with essential
              freelancing skills. We offer short courses and diplomas in areas
              like bookkeeping and graphic design, empowering professionals to
              thrive in the digital economy.
            </p>
            <p className="text-xs font-light text-justify text-gray-600 sm:text-sm">
              Rooted in innovation and community, we foster growth through
              tailored training, collaboration, and knowledge sharing. Our
              expert team is committed to integrity, excellence, and creating
              opportunities that drive success.
            </p>
          </div>

          <div className="mt-4 flex flex-wrap items-center gap-y-2">
            <div className="flex w-1/2 items-center">
              <span>
                <FaCheckCircle className="mr-3 text-custom-orange" />
              </span>
              <p className="m-0 text-xs font-medium text-custom-black xl:text-sm">
                All In One IT Solution Company
              </p>
            </div>
            <div className="flex w-1/2 items-center">
              <span>
                <FaCheckCircle className="mr-3 text-custom-orange" />
              </span>
              <p className="m-0 text-xs font-medium text-custom-black xl:text-sm">
                Amazing Expert Teams
              </p>
            </div>
            <div className="flex w-1/2 items-center">
              <span>
                <FaCheckCircle className="mr-3 text-custom-orange" />
              </span>
              <p className="m-0 text-xs font-medium text-custom-black xl:text-sm">
                Quality Solution For Business
              </p>
            </div>
            <div className="flex w-1/2 items-center">
              <span>
                <FaCheckCircle className="mr-3 text-custom-orange" />
              </span>
              <p className="m-0 text-xs font-medium text-custom-black xl:text-sm">
                24/7 Quick Supports
              </p>
            </div>
          </div>
          <div className="mt-6">
            <Link to="/about">
              <Button
                className={
                  "bg-custom-black uppercase text-white hover:bg-custom-dark-blue"
                }
              >
                Learn More <FaArrowRight className="ml-3 text-white" />
              </Button>
            </Link>
          </div>
        </div>

        <div className="relative order-1 mb-10 w-[100%] flex-1 sm:w-[70%] lg:order-2 lg:mb-0 lg:w-[unset]">
          <div className="relative mb-3 w-[100%] overflow-hidden">
            <img
              src={AboutImg}
              alt="about img"
              className="h-auto max-h-[260px] w-full rounded-2xl object-cover shadow-lg"
            />
          </div>
          <div className="flex h-full flex-wrap justify-between">
            <div className="mb-6 max-h-[100%] w-full sm:mb-0 sm:w-[67%]">
              <img
                src={DemoImgTwo}
                alt="about img"
                className="h-full max-h-[260px] w-full rounded-2xl object-cover shadow-lg"
              />
            </div>
            <div className="max-h-[100%] w-full sm:w-[30%]">
              <img
                src={DemoImgThree}
                alt="about img"
                className="h-full max-h-[260px] w-full rounded-2xl object-cover shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AboutSection;
